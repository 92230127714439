import type { FC } from "react";

import { cn } from "@dokworks/shared";
import { Spinner } from "@dokworks/ui";

export const ViewLoader: FC<{ className?: string }> = ({ className }) => (
  <div
    className={cn(
      "floating flex flex-1 items-center justify-center gap-2 font-semibold",
      className,
    )}
  >
    <Spinner className="text-fg-accent" /> Loading...
  </div>
);
