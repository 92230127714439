import { createFileRoute, redirect } from "@tanstack/react-router";

import { routeFallback } from "@/utils/const";

export const Route = createFileRoute("/")({
  beforeLoad: ({ context: { auth } }) => {
    throw redirect({
      to: auth.isAuthenticated ? routeFallback : "/login",
      replace: true,
    });
  },
});
