import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";

import { AuthProvider, useAuth } from "@/auth";
import { PreferenceProvider, usePreferences } from "@/preferences";
// Import first to apply the router type globally
import { router } from "@/router";
import { queryClient } from "@/utils/queryClient";

function InnerApp() {
  const auth = useAuth();
  const preferences = usePreferences();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={router}
        context={{ auth, queryClient, preferences }}
      />
    </QueryClientProvider>
  );
}

function App() {
  return (
    <PreferenceProvider>
      <AuthProvider>
        <InnerApp />
      </AuthProvider>
    </PreferenceProvider>
  );
}

export { App };
