import type { ReactNode } from "react";

import {
  useRouter,
  type ErrorComponentProps,
  type ErrorRouteComponent,
} from "@tanstack/react-router";

import { isHTTPError } from "@dokworks/fetch";
import { Button } from "@dokworks/ui";

import { useAuth } from "@/auth";

export const DefaultErrorComponent: ErrorRouteComponent = ({
  error,
  reset,
}: ErrorComponentProps) => {
  const router = useRouter();
  const auth = useAuth();

  if (isHTTPError(error)) {
    return wrap(
      <div className="flex max-w-full flex-col gap-2 regular:w-small wide:w-medium">
        <h1 className="mb-12 text-center font-display text-6xl font-semibold regular:text-8xl">
          {error.response.status}
        </h1>
        <div className="h-40 max-w-full overflow-scroll rounded border border-brd-danger-emphasis bg-canvas-danger-muted px-4 py-2 text-fg-danger shadow-inner shadow-brd-danger">
          {error.message.split("\n").map((line, index) => (
            <p className="leading-tight" key={`error_line_${index}`}>
              {line}
            </p>
          ))}
        </div>

        <div className="flex items-center gap-2">
          <Button
            variant="accent"
            className="flex-1"
            onPress={() => {
              // Reset the router error boundary
              reset();
              // Invalidate the route to reload the loader
              router.invalidate();
            }}
          >
            retry
          </Button>
          <Button
            className="flex-1"
            variant="danger"
            onPress={() => {
              auth.logout().then(() => {
                router.invalidate().then(() => {
                  router.navigate({ to: "/login" });
                });
              });
            }}
          >
            Logout
          </Button>
        </div>
      </div>,
    );
  }

  return wrap(
    <div className="flex max-w-full flex-col gap-2 regular:w-small wide:w-medium">
      <h1 className="mb-12 text-center font-display text-3xl font-semibold text-fg-danger regular:text-6xl">
        {error.name}
      </h1>
      <div className="relative h-40 max-w-full overflow-scroll rounded border border-brd-danger-emphasis bg-canvas-danger-muted px-1 pb-2 font-mono text-sm text-fg-danger shadow-inner shadow-brd-danger">
        <div className="sticky top-0 bg-canvas-danger-muted pt-2">
          <p className=" line-clamp-3  px-2 pb-1 font-semibold leading-tight">
            {error.message}
          </p>
          <div className="mb-1 border-b border-brd-danger-emphasis"></div>
        </div>
        {error.stack?.split("\n").map((line, index) => (
          <p className="px-2 leading-tight" key={`error_line_${index}`}>
            {line}
          </p>
        ))}
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="accent"
          className="flex-1"
          onPress={() => {
            // Reset the router error boundary
            reset();
            // Invalidate the route to reload the loader
            router.invalidate();
          }}
        >
          retry
        </Button>
        <Button
          className="flex-1"
          variant="danger"
          onPress={() => {
            auth.logout().then(() => {
              router.invalidate().then(() => {
                router.navigate({ to: "/login" });
              });
            });
          }}
        >
          Logout
        </Button>
      </div>
    </div>,
  );

  function wrap(content: ReactNode): ReactNode {
    return (
      <div className="floating flex flex-1 justify-center px-3 py-2 narrow:pt-12 regular:items-center">
        {content}
      </div>
    );
  }
};
