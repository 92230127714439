import { useEffect, useMemo, useRef, useState } from "react";

import { getLocalTimeZone, now, Time, toTime } from "@internationalized/date";
import {
  createFileRoute,
  useLoaderData,
  useRouterState,
} from "@tanstack/react-router";

import { cn } from "@dokworks/shared";

import { ViewLoader } from "@/components/viewLoader";

export const Route = createFileRoute("/_auth/dossier/")({
  component: DossierIndexComponent,
});

function DossierIndexComponent() {
  const user = useLoaderData({
    from: "/_auth",
  });

  const routeIsLoading = useRouterState({
    select: (state) => state.isLoading || state.isTransitioning,
  });

  const [iframeIsLoading, setIframeIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIframeIsLoading(true);
    iframeRef.current!.src = `/vue/`;
  }, []);

  const isLoading = routeIsLoading || iframeIsLoading;

  return (
    <>
      <div
        className={cn("floating flex flex-1 flex-col gap-4", {
          hidden: isLoading,
        })}
      >
        <h1 className="mt-8 px-6 font-display text-3xl font-semibold">
          <Greeting name={user.firstName} />
        </h1>
        <iframe
          ref={iframeRef}
          className={"min-w-full max-w-full flex-1"}
          onLoad={() => {
            setIframeIsLoading(false);
          }}
        ></iframe>
      </div>

      <ViewLoader className={cn({ hidden: !isLoading })} />
    </>
  );
}

type GreetingProps = {
  name: string;
};

const midnight = new Time(0);
const midday = new Time(12);
const evening = new Time(17);

function Greeting(props: GreetingProps) {
  const [currentTime, setCurrentTime] = useState(
    toTime(now(getLocalTimeZone())),
  );

  useEffect(
    function updateCurrenTime() {
      const id = setInterval(() => {
        setCurrentTime(toTime(now(getLocalTimeZone())));
      }, 60_000);

      return () => {
        clearInterval(id);
      };
    },
    [setCurrentTime],
  );

  const greeting = useMemo(() => {
    // if is midnight and not morning
    if (currentTime.compare(midnight) >= 0 && currentTime.compare(midday) < 0) {
      return "Goedemorgen";
    }

    if (currentTime.compare(midday) >= 0 && currentTime.compare(evening) < 0) {
      return "Goedemiddag";
    }

    return "Goedenavond";
  }, [currentTime]);

  return (
    <>
      {greeting}
      <span className="text-fg-accent"> {props.name}</span>
    </>
  );
}
