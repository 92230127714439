import { queryOptions } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

import { fetchDossier } from "@dokworks/fetch/dossier";

import { api } from "@/utils/fetch/api";

export const Route = createFileRoute("/_auth/dossier/$dossierId")({
  loader: async ({ context, params }) => {
    await context.queryClient.ensureQueryData(
      queryOptions({
        queryKey: ["dossier", { id: params.dossierId }],
        queryFn: async () => {
          const dossier = await fetchDossier(params.dossierId, api);

          return dossier;
        },
      }),
    );
  },
});
