import type { FC } from "react";

import { SettingsIcon } from "lucide-react";
import { TooltipTrigger } from "react-aria-components";

import { Tooltip } from "@dokworks/ui";

import { TSRACLinkButton } from "@/components/linkButton";

type SettingsButton = {
  isDisabled?: boolean;
};

export const SettingsButton: FC<{ isDisabled?: boolean }> = (props) => {
  const label = "User settings";

  return (
    <TooltipTrigger isDisabled={props.isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to="/settings"
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="!rounded-b data-[disabled]:!text-fg-on-emphasis/60"
        disabled={props.isDisabled}
      >
        <SettingsIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};
