import type { FC } from "react";

import { FolderOpen20Filled } from "@fluentui/react-icons";
import { useQuery } from "@tanstack/react-query";

import { cn } from "@dokworks/shared";

import { dossierQueryOptions } from "@/utils/fetch/dossier";

type DossierTitleProps = {
  dossierId: string;
  className?: string;
};

export const DossierTitle: FC<DossierTitleProps> = ({
  dossierId,
  className,
}) => {
  const { data: DossierTitle, isLoading } = useQuery({
    ...dossierQueryOptions(dossierId),
    select: (data) => data.title || String(data.dossierId) || data.id,
  });

  return (
    <h1
      className={cn(
        "flex w-xsmall max-w-max items-center gap-1 truncate px-2 text-center font-display text-base font-medium text-fg-accent",
        className,
      )}
    >
      <FolderOpen20Filled />
      {isLoading ? "..." : DossierTitle || "Onbekend dossier"}
    </h1>
  );
};
