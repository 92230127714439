import {
  createFileRoute,
  Outlet,
  redirect,
  useRouter,
} from "@tanstack/react-router";

import { cn } from "@dokworks/shared";

import { useAuth } from "@/auth";
import { usePrefersFixedWidth } from "@/preferences";
import { Header } from "@/routes/_auth/-components/header";
import { userQueryOptions } from "@/utils/fetch/user";
import { MainToolbar } from "@/components/toolbar/mainToolbar";

export const Route = createFileRoute("/_auth")({
  beforeLoad: async ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },

  loader: (opts) => opts.context.queryClient.ensureQueryData(userQueryOptions),

  component: AuthLayout,
});

function AuthLayout() {
  const router = useRouter();
  const navigate = Route.useNavigate();
  const auth = useAuth();

  const [prefersFixedWith] = usePrefersFixedWidth();

  const handleLogout = (gesture: "cancel" | "close" | "confirm") => {
    if (gesture === "confirm") {
      auth.logout().then(() => {
        router.invalidate().finally(() => {
          navigate({ to: "/login" });
        });
      });
    }
  };

  return (
    <div
      className={cn("layout relative flex-1", {
        "mx-auto w-xxlarge max-w-full": prefersFixedWith,
      })}
    >
      <Header handleLogout={handleLogout} />
      <Outlet />
      <MainToolbar />
    </div>
  );
}
