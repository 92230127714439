import { useEffect, useState } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { DownloadIcon, FilePenLineIcon } from "lucide-react";
import { TooltipTrigger } from "react-aria-components";

import { generateDocument } from "@dokworks/fetch/document";
import { Spinner, Tooltip } from "@dokworks/ui";

import { SignDocumentButton } from "@/routes/_auth/dossier/$dossierId/document/-components/signDocumentButton";
import { documentSignWorkflowType } from "@/utils/const";
import { api } from "@/utils/fetch/api";
import { dossierDocumentQueryOptions } from "@/utils/fetch/document";
import { dossierQueryOptions } from "@/utils/fetch/dossier";
import { TSRACLinkButton } from "@/components/linkButton";
import { ViewLoader } from "@/components/viewLoader";

export const Route = createFileRoute(
  "/_auth/dossier/$dossierId/document/$documentId/",
)({
  component: PDFComponent,
  pendingComponent: ViewLoader,
  // very important preloadStaleTime is defined because the global default is set to 0
  preloadStaleTime: 60_000,
  loader: async ({ params, abortController }) => {
    const file = await generateDocument(params.documentId, api, {
      signal: abortController.signal,
    });

    return { file };
  },
});

function PDFComponent() {
  const documentNavigate = useNavigate({
    from: "/dossier/$dossierId/document",
  });
  const params = Route.useParams();
  const { file } = Route.useLoaderData();
  const [documentUri, setDocumentUri] = useState<string>();
  const documentId = Route.useParams({ select: (s) => s.documentId });
  const { data: document, isFetching } = useQuery(
    dossierDocumentQueryOptions(documentId),
  );
  const { data: canSign = false } = useQuery({
    ...dossierQueryOptions(params.dossierId),
    select: (data) =>
      data.permittedWorkflows.includes(documentSignWorkflowType),
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    const uri = URL.createObjectURL(file);

    setDocumentUri(uri);

    return () => {
      URL.revokeObjectURL(uri);
    };
  }, [file, setDocumentUri]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        <object
          type={file.type}
          data={documentUri + "#zoom=page-fit&pagemod=bookmarks"}
          className="flex flex-1"
        >
          <ViewLoader className="rounded-b-none" />
        </object>
        <div className="floating flex select-none flex-wrap gap-2 rounded-t-none border-t px-6 py-3 regular:justify-end">
          <TooltipTrigger>
            <TSRACLinkButton
              to={documentUri}
              size="icon-medium"
              variant="success"
              disabled={!document || !documentUri}
              download={document?.name || true}
            >
              {isFetching ? (
                <Spinner size="small" />
              ) : (
                <DownloadIcon className="size-5" />
              )}
            </TSRACLinkButton>
            <Tooltip placement="top">Download bestand</Tooltip>
          </TooltipTrigger>
          <TooltipTrigger>
            <TSRACLinkButton
              to="/dossier/$dossierId/document/$documentId/edit"
              params={params}
              size="medium"
              variant="outline"
              className="narrow:flex-1"
            >
              Edit
              <FilePenLineIcon className="size-5" />
            </TSRACLinkButton>
            <Tooltip placement="top">Pas text aan in het document</Tooltip>
          </TooltipTrigger>
          <TooltipTrigger>
            <SignDocumentButton
              documentId={params.documentId}
              dossierId={params.dossierId}
              navigate={documentNavigate}
              queryClient={queryClient}
              canSign={canSign}
              variant="accent"
            />
            <Tooltip placement="top">Onderteken het document</Tooltip>
          </TooltipTrigger>
        </div>
      </div>
    </>
  );
}
