import { queryOptions } from "@tanstack/react-query";

import type {
  DataWithPagination,
  DossierDocument,
  DossierDocumentListItem,
} from "@dokworks/shared";

import { api } from "@/utils/fetch/api";

const dossierDocumentListQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", "documents", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const res = await api.get(`documents/${dossierId}`, { signal });
      const paginatedData =
        await res.json<DataWithPagination<DossierDocumentListItem>>();

      return paginatedData;
    },
  });

const dossierDocumentQueryOptions = (documentId: string) =>
  queryOptions({
    queryKey: ["dossier", "document", { id: documentId }],

    queryFn: async ({ signal }) => {
      const res = await api.get(`document/${documentId}`, { signal });
      const document = await res.json<DossierDocument>();

      return document;
    },
  });

const createDossierDocumentQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", "documents", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const res = await api.post("document/create", {
        json: { dossier_id: dossierId },
        signal,
      });

      const { documentId } = await res.json<{ documentId: string }>();

      const document = await api
        .get(`document/${documentId}`, { signal })
        .json<DossierDocument>();

      return document;
    },
  });

export {
  dossierDocumentListQueryOptions,
  dossierDocumentQueryOptions,
  createDossierDocumentQueryOptions,
};
