import {
  FC,
  startTransition,
  useRef,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react";

import {
  Building20Filled,
  Call20Filled,
  DismissCircle20Filled,
  WalletCreditCard20Filled,
} from "@fluentui/react-icons";
import { useLandmark } from "@react-aria/landmark";
import { useLoaderData } from "@tanstack/react-router";
import { CopyIcon, PowerIcon } from "lucide-react";
import { useTooltip } from "react-aria";
import {
  DialogTrigger,
  Provider,
  TooltipContext,
  TooltipTrigger,
  TooltipTriggerStateContext,
} from "react-aria-components";
import { useTooltipTriggerState } from "react-stately";

import {
  cn,
  useMedia,
  useSafeTimeout,
  viewportRanges,
  type User,
} from "@dokworks/shared";
import {
  Button,
  ConfirmationDialog,
  Dialog,
  Link,
  Modal,
  Popover,
  ToggleButton,
  Toolbar,
  Tooltip,
} from "@dokworks/ui";

import { DossierContextChecker } from "@/routes/_auth/dossier/-components/dossierContextChecker";
import { DossierTitle } from "@/routes/_auth/dossier/-components/dossierTitle";
import { routeFallback } from "@/utils/const";
import { Avatar } from "@/components/avatar";
import { Icon } from "@/components/icon";
import { TSRACLink } from "@/components/link";
import { SettingsButton } from "@/components/navigation/components/settingsButton";

export const Header: FC<{
  handleLogout: (gesture: "cancel" | "close" | "confirm") => void;
}> = ({ handleLogout }) => {
  const ref = useRef<HTMLElement | null>(null);
  const user = useLoaderData({
    from: "/_auth",
  });

  const { landmarkProps } = useLandmark(
    {
      role: "complementary",
      "aria-label": "Dok.legal header",
    },
    ref,
  );

  return (
    <nav
      {...landmarkProps}
      className="layout-header flex flex-wrap justify-stretch gap-2"
    >
      <div className="flex flex-1 items-center justify-start gap-2">
        <TSRACLink
          to={routeFallback}
          className="m-2 flex size-6 select-none items-center justify-center overflow-visible data-[pressed]:outline data-[pressed]:outline-fg-link"
        >
          <Icon className="size-5 text-fg" />
        </TSRACLink>
        <p className="hidden select-none items-center truncate text-left font-display font-medium leading-none regular:flex">
          <Building20Filled className="mr-0.5 text-fg-accent" />
          {user.organisation.name}
        </p>
      </div>
      <div className="flex flex-1 items-center justify-center gap-2">
        <DossierContextChecker>
          {(dossierId) => <DossierTitle dossierId={dossierId} />}
        </DossierContextChecker>
      </div>
      <Toolbar
        orientation="horizontal"
        className="flex flex-1 flex-wrap items-center justify-end gap-1"
      >
        <SettingsButton />
        <ProfileButton user={user}>
          <MiniProfile user={user} />
        </ProfileButton>
        <DialogTrigger>
          <TooltipTrigger delay={300}>
            <Button
              variant="floating"
              size="icon-medium"
              className="data-[hovered]:rounded"
            >
              <PowerIcon className="size-5" />
            </Button>
            <Tooltip placement="left">Logout</Tooltip>
          </TooltipTrigger>
          <Modal className="max-w-small">
            <ConfirmationDialog
              title="Weet je zeker dat je uit wil loggen?"
              footerButtons={[
                { children: "Annuleer", variant: "outline" },
                { children: "Log uit", variant: "danger", autoFocus: true },
              ]}
              onClose={handleLogout}
            />
          </Modal>
        </DialogTrigger>
      </Toolbar>
    </nav>
  );
};

interface ProfileButtonProps {
  user: User;
}

function ProfileButton({
  user,
  children,
}: PropsWithChildren<ProfileButtonProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const isWideViewport = useMedia(viewportRanges.wide);

  return (
    <DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <ToggleButton
        variant="floating"
        size={isWideViewport ? "medium" : "icon-medium"}
        className="font-semibold wide:pr-2"
        isSelected={isOpen}
      >
        <p className="hidden max-w-60 truncate text-left font-system leading-tight wide:block">
          {user.fullName}
        </p>

        {isOpen ? (
          <DismissCircle20Filled className="size-6" />
        ) : (
          <Avatar email={user.email} size={24} />
        )}
      </ToggleButton>
      <Popover
        placement="bottom end"
        className="w-[18rem] rounded shadow-lg ring-1 ring-brd"
        crossOffset={-16}
        offset={12}
        showArrow
      >
        <Dialog>{children}</Dialog>
      </Popover>
    </DialogTrigger>
  );
}

interface MiniProfileProps {
  user: User;
}

function MiniProfile({ user }: MiniProfileProps) {
  return (
    <>
      <h1 className="mb-2 mt-0.5 font-display text-2xl">{user.fullName}</h1>
      <MiniProfileDetail
        title="Organisatie"
        textValue={user.organisation.name}
        icon={<Building20Filled className="text-fg-accent" />}
      >
        <p className="indent-1 text-sm leading-none">
          {user.organisation.name}
        </p>
      </MiniProfileDetail>
      <MiniProfileDetail
        title="Credits"
        textValue={user.creditBalance.toString()}
        icon={<WalletCreditCard20Filled className="text-fg-accent" />}
      >
        <p
          className={cn("indent-1 font-mono text-sm leading-none", {
            "text-fg-danger": user.creditBalance < 1,
            "text-fg-success": user.creditBalance > 0,
          })}
        >
          {user.creditBalance !== 0 && (
            <span className="select-none">
              {user.creditBalance > 0 ? "+" : "-"}
            </span>
          )}
          {user.creditBalance}
        </p>
      </MiniProfileDetail>
      <MiniProfileDetail
        title="Telefoon"
        textValue={user.phoneNumber}
        icon={<Call20Filled className="text-fg-accent" />}
      >
        {user.phoneNumber ? (
          <div className="flex items-center justify-between">
            <Link
              href={`tel:${user.phoneNumber}`}
              className="indent-1 font-mono text-sm leading-none"
            >
              {user.phoneNumber}
            </Link>
          </div>
        ) : (
          <p className="indent-1 font-mono text-sm leading-none">-</p>
        )}
      </MiniProfileDetail>
    </>
  );
}

interface MiniProfileDetailProps {
  title: string;
  textValue: string;
  icon: ReactNode;
}

function MiniProfileDetail({
  title,
  textValue,
  icon,
  children,
}: PropsWithChildren<MiniProfileDetailProps>) {
  const timeoutId = useRef<number>();
  const { safeClearTimeout, safeSetTimeout } = useSafeTimeout();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [copyMessage, setCopyMessage] = useState<string>("");

  const state = useTooltipTriggerState({ delay: 0 });
  const { tooltipProps } = useTooltip({}, state);

  return (
    <div className="group/user-detail -mx-4 flex items-center gap-2 rounded px-3 py-2 hover:bg-canvas-muted">
      {icon}
      <div className="flex flex-1 flex-col gap-1">
        <h2 className="select-none font-display font-medium leading-none">
          {title}
        </h2>
        {children}
      </div>
      {textValue && (
        <>
          <Button
            aria-label={"Kopieer " + title.toLowerCase()}
            ref={buttonRef}
            size="icon-small"
            variant="ghost"
            className="hidden group-hover/user-detail:inline-flex"
            onPress={handleCopy}
          >
            <CopyIcon className="size-4" />
          </Button>
          <Provider
            values={[
              [TooltipTriggerStateContext, state],
              [
                TooltipContext,
                {
                  ...tooltipProps,
                  triggerRef: buttonRef,
                },
              ],
            ]}
          >
            <Tooltip>{copyMessage}</Tooltip>
          </Provider>
        </>
      )}
    </div>
  );

  async function handleCopy() {
    const type = "text/plain";
    const blob = new Blob([textValue], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    try {
      await navigator.clipboard.write(data);
      startTransition(() => {
        setCopyMessage("Gekopieerd!");
        if (!state.isOpen) {
          state.open();
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        } else if (timeoutId.current !== undefined) {
          safeClearTimeout(timeoutId.current);
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        }
      });
    } catch (e) {
      startTransition(() => {
        setCopyMessage("Kon niet kopiëren.");
        state.open(true);
        if (!state.isOpen) {
          state.open();
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        } else if (timeoutId.current !== undefined) {
          safeClearTimeout(timeoutId.current);
          timeoutId.current = safeSetTimeout(() => {
            state.close();
          }, 500);
        }
      });
      console.error(e);
    }
  }
}
