import { forwardRef, useMemo } from "react";

import { createLink, LinkComponent } from "@tanstack/react-router";
import {
  mergeProps,
  useFocusRing,
  useHover,
  useLink,
  useObjectRef,
  type AriaLinkOptions,
} from "react-aria";

import { styledLink } from "@dokworks/ui";

interface RACLinkProps extends Omit<AriaLinkOptions, "href" | "isDisabled"> {
  className?: string;
  children?: React.ReactNode;
}

const RACLinkComponent = forwardRef<HTMLAnchorElement, RACLinkProps>(
  (propsWithOnClick, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);

    const { onClick, ...props } = propsWithOnClick as RACLinkProps & {
      onClick?: () => void;
    };

    const { linkProps, isPressed } = useLink(
      { ...props, onPress: onClick },
      ref,
    );
    const isDisabled = useMemo(
      () => !("href" in linkProps) || !linkProps.href,
      [linkProps],
    );

    const { hoverProps, isHovered } = useHover({ ...props, isDisabled });
    const { focusProps, isFocusVisible, isFocused } = useFocusRing(props);
    return (
      <a
        {...mergeProps(linkProps, hoverProps, focusProps, props)}
        ref={ref}
        data-hovered={isHovered || undefined}
        data-pressed={isPressed || undefined}
        data-focus-visible={isFocusVisible || undefined}
        data-focused={isFocused || undefined}
        data-disabled={isDisabled || undefined}
        className={styledLink({
          isHovered,
          isFocused,
          isFocusVisible,
          isDisabled,
          className: props.className,
        })}
      />
    );
  },
);

RACLinkComponent.displayName = "RACLinkComponent";

const CreatedLinkComponent = createLink(RACLinkComponent);

export const TSRACLink: LinkComponent<typeof RACLinkComponent> = (props) => (
  <CreatedLinkComponent preload={"intent"} {...props} />
);
