import { useMutation, type QueryClient } from "@tanstack/react-query";
import type { UseNavigateResult } from "@tanstack/react-router";
import { SignatureIcon } from "lucide-react";
import type { Key } from "react-stately";

import { cn, WorkflowType } from "@dokworks/shared";
import { Button, Spinner, type ButtonProps } from "@dokworks/ui";

import { documentSignWorkflowType } from "@/utils/const";
import { api } from "@/utils/fetch/api";
import { workflowListQueryOptions } from "@/utils/fetch/workflow";

type SignDocumentButtonProps = {
  documentId: Key;
  dossierId: string;
  canSign: boolean;
  queryClient: QueryClient;
  variant?: ButtonProps["variant"];
  className?: string;
  navigate: UseNavigateResult<"/dossier/$dossierId/document">;
};

export function SignDocumentButton({
  dossierId,
  queryClient,
  navigate,
  documentId,
  canSign,
  variant = "outline",
  className,
}: SignDocumentButtonProps) {
  const {
    mutate: createDocumentSignWorkflow,
    isPending: isPendingDocumentSignWorkflow,
  } = useMutation({
    mutationFn: async (documentId: Key) => {
      await api.post("workflow_v2/workflow/", {
        json: {
          workflow_type: documentSignWorkflowType,
          dossier_id: dossierId,
          data: {
            document: {
              reference: documentId,
            },
          },
        },
      });
    },
    onSuccess: () => {
      navigate({
        to: "/dossier/$dossierId/workflows",
        params: {
          dossierId,
        },
      });
    },
    onSettled: (_, err) => {
      if (err) {
        console.error(err);
      }

      queryClient.invalidateQueries(
        workflowListQueryOptions({
          type: WorkflowType.AUTO,
          dossierId,
        }),
      );
    },
  });

  return (
    <Button
      variant={variant}
      className={cn({ "text-fg-accent": variant === "outline" }, className)}
      isDisabled={!canSign}
      isPending={isPendingDocumentSignWorkflow}
      onPress={() => signDocument()}
    >
      <span className="mt-0.5">Onderteken</span>
      {isPendingDocumentSignWorkflow ? (
        <span className="inline-flex size-5 items-center justify-center">
          <Spinner size="small" />
        </span>
      ) : (
        <SignatureIcon className="size-5" />
      )}
    </Button>
  );

  function signDocument() {
    createDocumentSignWorkflow(documentId);
  }
}
