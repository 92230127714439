import { createFileRoute } from "@tanstack/react-router";

import {
  defaultFilter,
  logListSchema,
  logsQueryOptions,
} from "@/utils/fetch/log";

import { DossiersLog } from "./-components/dossiersLog";

export const Route = createFileRoute("/_auth/dossier/$dossierId/logs")({
  validateSearch: logListSchema,
  loader: async ({ context, params }) => {
    context.queryClient.prefetchInfiniteQuery(
      logsQueryOptions(defaultFilter, params.dossierId),
    );
  },
  component: LogsComponent,
});

function LogsComponent() {
  return (
    <div className="floating flex flex-1 flex-col gap-4 px-6">
      <h1 className="mt-8 font-display text-3xl font-semibold">Audit log</h1>
      <div className="mt-2 rounded-md border border bg-canvas pt-4 shadow-sm">
        <DossiersLog className="rounded-md" />
      </div>
    </div>
  );
}
