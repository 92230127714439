import { useParams } from "@tanstack/react-router";

export function useDossierIdFromParams() {
  const id = useParams({
    strict: false,
    select: (params) => params.dossierId,
  });

  return id;
}
