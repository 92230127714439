import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from "@tanstack/react-query";

import type { HTTPError } from "@dokworks/fetch";
import { fetchDossier } from "@dokworks/fetch/dossier";
import { type DataWithPagination, type Model } from "@dokworks/shared";

import { api } from "@/utils/fetch/api";
import { mergeParams, type Paginated } from "@/utils/fetch/common";

interface DossierFile extends Model {
  name: string;
  file: string;
  fileType: string;
  created: string;
}
export interface DossierWorkflowDir {
  workflowRef: string;
  files: DossierFile[];
}

const dossierQueryOptions = (dossierId: string) =>
  queryOptions({
    queryKey: ["dossier", { id: dossierId }],
    queryFn: async ({ signal }) => {
      const dossier = await fetchDossier(dossierId, api, { signal });

      return dossier;
    },
  });

const dossierFilesQueryOptions = (
  opts: {
    dossierId: string;
    workflowId?: string;
  } & Paginated,
) =>
  infiniteQueryOptions<DataWithPagination<DossierWorkflowDir>, HTTPError>({
    queryKey: [
      "dossier",
      "files",
      { dossierId: opts.dossierId, ...{ workflowId: opts.workflowId } },
      { limit: opts.limit, offset: opts.offset },
    ],
    staleTime: 30_000,
    placeholderData: keepPreviousData,

    getPreviousPageParam: (firstPage) => firstPage?.previous ?? null,
    getNextPageParam: (lastPage) => lastPage?.next ?? null,
    initialPageParam: `?limit=${opts.limit}&offset=${opts.offset}`,

    queryFn: async ({ signal, pageParam }) => {
      const data = await api
        .get("workflow_v2/dossier/" + opts.dossierId + "/files/", {
          searchParams: mergeParams(
            {
              limit: opts.limit,
              offset: opts.offset,
            },
            typeof pageParam === "string" ? pageParam : null,
          ),
          signal,
        })
        .json<DataWithPagination<DossierWorkflowDir>>();

      return data;
    },
  });

export { dossierQueryOptions, dossierFilesQueryOptions };
