import { createContext, useCallback, useContext } from "react";

import { useActorRef, useSelector } from "@xstate/react";
import { Actor } from "xstate";

import {
  preferenceLogic,
  type PreferenceLogic,
} from "@dokworks/logic/preference";

export interface PreferencesContext {
  actorRef: Actor<PreferenceLogic>;
  send: Actor<PreferenceLogic>["send"];
}

const PreferencesContext = createContext<PreferencesContext | null>(null);

export function PreferenceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const actorRef = useActorRef(preferenceLogic);

  const send = useCallback<Actor<PreferenceLogic>["send"]>(
    (event) => actorRef.send(event),
    [actorRef],
  );

  return (
    <PreferencesContext.Provider value={{ actorRef, send }}>
      {children}
    </PreferencesContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePreferences(): PreferencesContext {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new Error("usePreferences must be used within an PreferenceProvider");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePrefersFixedWidth(): [boolean, (value: boolean) => void] {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new Error(
      "usePrefersFixedWidth must be used within an PreferenceProvider",
    );
  }

  const prefersFixedWith = useSelector(
    context.actorRef,
    (snapshot) => snapshot.context.prefersFixedWith,
  );

  const setPrefersFixedWidth = (value: boolean): void => {
    context.send({ type: "update-fixed-width", value });
  };

  return [prefersFixedWith, setPrefersFixedWidth];
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePrefersGravatar(): [boolean, (value: boolean) => void] {
  const context = useContext(PreferencesContext);

  if (!context) {
    throw new Error(
      "usePrefersFixedWidth must be used within an PreferenceProvider",
    );
  }

  const prefersGravatar = useSelector(
    context.actorRef,
    (snapshot) => snapshot.context.prefersGravatarAvatar,
  );

  const setPrefersGravatar = (value: boolean): void => {
    context.send({ type: "update-gravatar-avatar", value });
  };

  return [prefersGravatar, setPrefersGravatar];
}
