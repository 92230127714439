import type { FC } from "react";

import { FullscreenIcon, GalleryHorizontalIcon } from "lucide-react";
import { Group, Separator, TooltipTrigger } from "react-aria-components";

import { Button, Tooltip } from "@dokworks/ui";

import { usePrefersFixedWidth } from "@/preferences";

export const MainToolbarActions: FC = () => {
  const [prefersFixedWith, setPrefersFixedWidth] = usePrefersFixedWidth();

  return (
    <Group
      aria-label="Toolbar acties"
      className="flex flex-col items-center gap-1"
    >
      <Separator
        orientation="horizontal"
        className="mx-auto my-1 w-1/2 rounded-full border-canvas/40"
      />
      <TooltipTrigger>
        <Button
          size="icon-medium"
          variant="floating"
          aria-label="Toggle layout mode"
          onPress={() => setPrefersFixedWidth(!prefersFixedWith)}
        >
          {prefersFixedWith ? (
            <FullscreenIcon className="size-5" />
          ) : (
            <GalleryHorizontalIcon className="size-5" />
          )}
        </Button>
        <Tooltip placement="left">Toggle layout mode - fixed / fluid</Tooltip>
      </TooltipTrigger>
    </Group>
  );
};
