import { useRef, type FC } from "react";

import { useToolbar } from "@react-aria/toolbar";
import { mergeProps } from "react-aria";

import { DossierNavigation } from "@/components/navigation/DossierNavigation";
import { MainToolbarActions } from "@/components/toolbar/components/mainToolbarActions";

export const MainToolbar: FC = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { toolbarProps } = useToolbar(
    { ...props, orientation: "vertical" },
    ref,
  );

  return (
    <div
      ref={ref}
      {...mergeProps(toolbarProps, props)}
      className="layout-rt flex flex-col justify-between"
    >
      <DossierNavigation />
      <MainToolbarActions />
    </div>
  );
};
