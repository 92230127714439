import { createFileRoute, notFound } from "@tanstack/react-router";
import { CornerDownLeftIcon, RefreshCcwIcon } from "lucide-react";

import { isHTTPError } from "@dokworks/fetch";
import { styledButton, UnstyledLink } from "@dokworks/ui";

import { dossierDocumentQueryOptions } from "@/utils/fetch/document";
import { ViewLoader } from "@/components/viewLoader";

export const Route = createFileRoute(
  "/_auth/dossier/$dossierId/document/$documentId",
)({
  notFoundComponent: DocumentNotFoundComponent,
  pendingComponent: ViewLoader,
  loader: async ({ context, params }) => {
    try {
      await context.queryClient.ensureQueryData(
        dossierDocumentQueryOptions(params.documentId),
      );
    } catch (err) {
      if (isHTTPError(err) && err.response.status === 404) {
        throw notFound({
          data: err.response.json(),
          headers: err.response.headers,
          routeId: "/_auth/dossier/$dossierId/document/$documentId",
        });
      }

      throw err;
    }
  },
});

function DocumentNotFoundComponent() {
  const params = Route.useParams();
  return (
    <div className="floating flex flex-1 flex-col gap-4 px-6 py-3">
      <h1 className="mt-5 font-display text-3xl font-semibold">
        <span className="text-fg-danger">404</span> Not Found.
      </h1>
      <p>
        Het document met referentie{" "}
        <code className="rounded bg-canvas-accent-muted px-2 py-1 text-fg-accent">
          {params.documentId}
        </code>{" "}
        kon niet gevonden worden.
      </p>
      <div className="mt-auto flex items-center justify-end gap-2">
        <UnstyledLink
          aria-label="Ga terug naar documentbeheer"
          href={`/dossier/${params.dossierId}/document/${params.documentId}`}
          className={(renderProps) =>
            styledButton({
              ...renderProps,
              variant: "outline",
            })
          }
        >
          Probeer het opnieuw
          <RefreshCcwIcon className="size-5" />
        </UnstyledLink>
        <UnstyledLink
          aria-label="Ga terug naar documentbeheer"
          href="../"
          className={(renderProps) =>
            styledButton({
              ...renderProps,
              variant: "accent",
            })
          }
        >
          Ga terug naar documentbeheer
          <CornerDownLeftIcon className="size-5" />
        </UnstyledLink>
      </div>
    </div>
  );
}
