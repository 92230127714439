import { useEffect, useState, type CSSProperties, type FC } from "react";

import { PersonCircleFilled } from "@fluentui/react-icons";

import { cn, hashText } from "@dokworks/shared";

import { usePrefersGravatar } from "@/preferences";

export type AvatarProps = {
  size?: number;
  email?: string;
  className?: string;
};

export const Avatar: FC<AvatarProps> = ({ size = 24, email, className }) => {
  const [prefersGravatar] = usePrefersGravatar();
  const [hash, setHash] = useState<string | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (prefersGravatar && email) {
      hashText(email).then(setHash);
    } else if (hash !== null) {
      setHash(null);
    }
  }, [prefersGravatar, hash, email, setHash]);

  const style: CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
  };

  if (!prefersGravatar || !hash || error) {
    return (
      <PersonCircleFilled
        width={size}
        height={size}
        style={style}
        className={className}
      />
    );
  }

  return (
    <span
      style={style}
      className={cn("inline-flex items-center justify-center", className)}
    >
      <img
        draggable={false}
        src={`https://www.gravatar.com/avatar/${hash}?d=404&s=${size}`}
        className="size-4/5 rounded-full bg-canvas shadow-[0_0_0_1px_#1f232826]"
        onError={() => setError(true)}
      />
    </span>
  );
};
