import type { FC } from "react";

import { MatchRoute } from "@tanstack/react-router";

type DossierContextCheckerProps = {
  children: (dossierId: string) => React.ReactNode | undefined;
};

export const DossierContextChecker: FC<DossierContextCheckerProps> = (
  props,
) => (
  <MatchRoute to="/dossier/$dossierId" fuzzy>
    {(match) => !!match && props.children(match.dossierId)}
  </MatchRoute>
);
