import { useRef, type FC } from "react";

import { useLandmark } from "@react-aria/landmark";
import {
  FileSlidersIcon,
  FolderEditIcon,
  FolderSearch2Icon,
  FoldersIcon,
  RouteIcon,
  ScrollTextIcon,
} from "lucide-react";
import { mergeProps } from "react-aria";
import { Separator, TooltipTrigger } from "react-aria-components";

import { Tooltip } from "@dokworks/ui";

import { useDossierIdFromParams } from "@/utils/hooks/dossier";
import { TSRACLinkButton } from "@/components/linkButton";

interface DossierNavigation {
  className?: string;
}

export const DossierNavigation: FC<DossierNavigation> = (props) => {
  const ref = useRef<HTMLElement | null>(null);
  const { landmarkProps } = useLandmark(
    { role: "navigation", "aria-label": "Main navigation bar" },
    ref,
  );

  return (
    <nav
      ref={ref}
      {...mergeProps(landmarkProps, props)}
      className="flex flex-col gap-1"
    >
      <DossiersButton />
      <Separator
        orientation="horizontal"
        className="mx-auto my-1 w-1/2 rounded-full border-canvas/40"
      />
      <DocumentManagerButton />
      <EditButton />
      <WorkflowButton />
      <StorageButton />
      <AuditLogButton />
    </nav>
  );
};

type NavigationButtonProps = {
  isDisabled?: boolean;
};

const DossiersButton: FC<NavigationButtonProps> = (props) => {
  const label = "Dossier overzicht";

  return (
    <TooltipTrigger isDisabled={props.isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to="/dossier"
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="!rounded-l data-[disabled]:!text-fg-on-emphasis/60"
        disabled={props.isDisabled}
      >
        <FoldersIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};

const EditButton: FC<NavigationButtonProps> = (props) => {
  const dossierId = useDossierIdFromParams();

  const isDisabled = props.isDisabled || !dossierId;

  const label = "Dossier gegevens aanpassen";

  return (
    <TooltipTrigger isDisabled={isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to={`/dossier/${dossierId}/edit`}
        search={{ page: 0, section: 0 }}
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        disabled={isDisabled}
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="data-[disabled]:text-fg-on-emphasis/60"
      >
        <FolderEditIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};

const DocumentManagerButton: FC<NavigationButtonProps> = (props) => {
  const dossierId = useDossierIdFromParams();

  const isDisabled = props.isDisabled || !dossierId;

  const label = "Documentbeheer";

  return (
    <TooltipTrigger isDisabled={isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to={`/dossier/${dossierId}/document`}
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        disabled={isDisabled}
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="data-[disabled]:text-fg-on-emphasis/60"
      >
        <FileSlidersIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};

const StorageButton: FC<NavigationButtonProps> = (props) => {
  const dossierId = useDossierIdFromParams();

  const isDisabled = props.isDisabled || !dossierId;

  const label = "Bestandsbeheer";

  return (
    <TooltipTrigger isDisabled={isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to={`/dossier/${dossierId}/storage`}
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        disabled={isDisabled}
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="data-[disabled]:text-fg-on-emphasis/60"
      >
        <FolderSearch2Icon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};

const WorkflowButton: FC<NavigationButtonProps> = (props) => {
  const dossierId = useDossierIdFromParams();

  const isDisabled = props.isDisabled || !dossierId;

  const label = "Workflow inzicht";

  return (
    <TooltipTrigger isDisabled={isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to={`/dossier/${dossierId}/workflows`}
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        disabled={isDisabled}
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="data-[disabled]:text-fg-on-emphasis/60"
      >
        <RouteIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};

const AuditLogButton: FC<NavigationButtonProps> = (props) => {
  const dossierId = useDossierIdFromParams();

  const isDisabled = props.isDisabled || !dossierId;

  const label = "Dossier logs";

  return (
    <TooltipTrigger isDisabled={isDisabled}>
      <TSRACLinkButton
        aria-label={label}
        to={`/dossier/${dossierId}/logs`}
        activeOptions={{
          exact: true,
        }}
        size="icon-medium"
        variant="floating"
        disabled={isDisabled}
        inactiveProps={{
          className: "data-[focus-visible]:!ring-fg-on-emphasis",
        }}
        className="data-[disabled]:text-fg-on-emphasis/60"
      >
        <ScrollTextIcon className="size-5" />
      </TSRACLinkButton>
      <Tooltip placement="left">{label}</Tooltip>
    </TooltipTrigger>
  );
};
