import { useEffect, useRef, useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

import { getAccessToken } from "@dokworks/fetch";
import { cn } from "@dokworks/shared";

import { TSRACLinkButton } from "@/components/linkButton";
import { ViewLoader } from "@/components/viewLoader";

export const Route = createFileRoute(
  "/_auth/dossier/$dossierId/document/$documentId/edit",
)({
  component: DocumentComponent,
});

function DocumentComponent() {
  const params = Route.useParams();
  const { dossierId, documentId } = params;
  const [isLoading, setIsLoading] = useState(true);
  const editorIframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    setIsLoading(true);
    editorIframeRef.current!.src = `https://editor.dok.legal/?api=${encodeURIComponent(__API_BASE_URL__)}&document=${
      documentId
    }&token=${getAccessToken(localStorage)}`;
  }, [documentId, dossierId]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        <ViewLoader className={cn("rounded-b-none", { hidden: !isLoading })} />
        <iframe
          ref={editorIframeRef}
          className={cn("min-w-full max-w-full flex-1 rounded-t-md", {
            hidden: isLoading,
          })}
          onLoad={() => {
            setIsLoading(false);
          }}
        ></iframe>
        <div className="floating flex select-none flex-wrap gap-2 rounded-t-none border-t px-6 py-3 regular:justify-end">
          <TSRACLinkButton
            to="/dossier/$dossierId/document/$documentId"
            params={params}
            size="medium"
            variant="accent"
            className="narrow:flex-1"
          >
            Genereer document
          </TSRACLinkButton>
        </div>
      </div>
    </>
  );
}
