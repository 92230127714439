import {
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import {
  fetchUser,
  fetchUserBackground,
  updateUser,
} from "@dokworks/fetch/user";
import type { User } from "@dokworks/shared";

import { api } from "@/utils/fetch/api";
import { getImageUrl } from "@/utils/helpers/asset.helper";

export const userQueryKey = ["user"];

export const userQueryOptions = queryOptions({
  queryKey: userQueryKey,
  queryFn: ({ signal }) => fetchUser(api, { signal }),
});

const background = getImageUrl("background/background_doklegal.jpeg");

export const userBackgroundQueryOptions = (authenticated?: boolean) =>
  queryOptions<string>({
    queryKey: userQueryKey.concat(["background"]),
    refetchOnWindowFocus: false,
    queryFn: async ({ signal }) => {
      if (!authenticated) return background;

      const url = await fetchUserBackground(api, {
        signal,
      })
        .then(async (res) => {
          if (res.status === 204) return background;

          const url = URL.createObjectURL(await res.blob());

          return url;
        })
        .catch(() => background);

      return url;
    },
    initialData: background,
    retry: 0,
  });

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      part: Partial<
        Pick<User, "firstName" | "preposition" | "lastName" | "phoneNumber">
      >,
    ) => {
      const user = await updateUser(part, api);

      return user;
    },
    onSuccess: async (user) => {
      await queryClient.setQueryData(userQueryKey, user);
    },
  });
};
