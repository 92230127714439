import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { routeFallback } from "@/utils/const";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || routeFallback });
    }
  },
});
