/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as LoginRouteImport } from "./routes/login/route";
import { Route as AuthRouteImport } from "./routes/_auth/route";
import { Route as IndexImport } from "./routes/index";
import { Route as LoginIndexImport } from "./routes/login/index";
import { Route as AuthDashboardImport } from "./routes/_auth/dashboard";
import { Route as AuthDossierRouteImport } from "./routes/_auth/dossier/route";
import { Route as LoginResetPasswordIndexImport } from "./routes/login/resetPassword.index";
import { Route as AuthDossierIndexImport } from "./routes/_auth/dossier/index";
import { Route as AuthDossierDossierIdRouteImport } from "./routes/_auth/dossier/$dossierId/route";
import { Route as AuthDossierDossierIdWorkflowsImport } from "./routes/_auth/dossier/$dossierId/workflows";
import { Route as AuthDossierDossierIdStorageImport } from "./routes/_auth/dossier/$dossierId/storage";
import { Route as AuthDossierDossierIdLogsImport } from "./routes/_auth/dossier/$dossierId/logs";
import { Route as AuthDossierDossierIdEditImport } from "./routes/_auth/dossier/$dossierId/edit";
import { Route as AuthDossierDossierIdDocumentIndexImport } from "./routes/_auth/dossier/$dossierId/document/index";
import { Route as LoginResetPasswordConfirmUidTokenImport } from "./routes/login/resetPassword.confirm.$uid.$token";
import { Route as AuthDossierDossierIdDocumentDocumentIdRouteImport } from "./routes/_auth/dossier/$dossierId/document/$documentId/route";
import { Route as AuthDossierDossierIdDocumentDocumentIdIndexImport } from "./routes/_auth/dossier/$dossierId/document/$documentId/index";
import { Route as AuthDossierDossierIdDocumentDocumentIdEditImport } from "./routes/_auth/dossier/$dossierId/document/$documentId/edit";

// Create Virtual Routes

const AuthSettingsRouteLazyImport = createFileRoute("/_auth/settings")();
const AuthDossierDossierIdDocumentRouteLazyImport = createFileRoute(
  "/_auth/dossier/$dossierId/document",
)();

// Create/Update Routes

const LoginRouteRoute = LoginRouteImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any).lazy(() => import("./routes/login/route.lazy").then((d) => d.Route));

const AuthRouteRoute = AuthRouteImport.update({
  id: "/_auth",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const LoginIndexRoute = LoginIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => LoginRouteRoute,
} as any);

const AuthSettingsRouteLazyRoute = AuthSettingsRouteLazyImport.update({
  id: "/settings",
  path: "/settings",
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import("./routes/_auth/settings/route.lazy").then((d) => d.Route),
);

const AuthDashboardRoute = AuthDashboardImport.update({
  id: "/dashboard",
  path: "/dashboard",
  getParentRoute: () => AuthRouteRoute,
} as any);

const AuthDossierRouteRoute = AuthDossierRouteImport.update({
  id: "/dossier",
  path: "/dossier",
  getParentRoute: () => AuthRouteRoute,
} as any).lazy(() =>
  import("./routes/_auth/dossier/route.lazy").then((d) => d.Route),
);

const LoginResetPasswordIndexRoute = LoginResetPasswordIndexImport.update({
  id: "/resetPassword/",
  path: "/resetPassword/",
  getParentRoute: () => LoginRouteRoute,
} as any);

const AuthDossierIndexRoute = AuthDossierIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthDossierRouteRoute,
} as any);

const AuthDossierDossierIdRouteRoute = AuthDossierDossierIdRouteImport.update({
  id: "/$dossierId",
  path: "/$dossierId",
  getParentRoute: () => AuthDossierRouteRoute,
} as any).lazy(() =>
  import("./routes/_auth/dossier/$dossierId/route.lazy").then((d) => d.Route),
);

const AuthDossierDossierIdDocumentRouteLazyRoute =
  AuthDossierDossierIdDocumentRouteLazyImport.update({
    id: "/document",
    path: "/document",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any).lazy(() =>
    import("./routes/_auth/dossier/$dossierId/document/route.lazy").then(
      (d) => d.Route,
    ),
  );

const AuthDossierDossierIdWorkflowsRoute =
  AuthDossierDossierIdWorkflowsImport.update({
    id: "/workflows",
    path: "/workflows",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any);

const AuthDossierDossierIdStorageRoute =
  AuthDossierDossierIdStorageImport.update({
    id: "/storage",
    path: "/storage",
    getParentRoute: () => AuthDossierDossierIdRouteRoute,
  } as any);

const AuthDossierDossierIdLogsRoute = AuthDossierDossierIdLogsImport.update({
  id: "/logs",
  path: "/logs",
  getParentRoute: () => AuthDossierDossierIdRouteRoute,
} as any);

const AuthDossierDossierIdEditRoute = AuthDossierDossierIdEditImport.update({
  id: "/edit",
  path: "/edit",
  getParentRoute: () => AuthDossierDossierIdRouteRoute,
} as any);

const AuthDossierDossierIdDocumentIndexRoute =
  AuthDossierDossierIdDocumentIndexImport.update({
    id: "/",
    path: "/",
    getParentRoute: () => AuthDossierDossierIdDocumentRouteLazyRoute,
  } as any);

const LoginResetPasswordConfirmUidTokenRoute =
  LoginResetPasswordConfirmUidTokenImport.update({
    id: "/resetPassword/confirm/$uid/$token",
    path: "/resetPassword/confirm/$uid/$token",
    getParentRoute: () => LoginRouteRoute,
  } as any);

const AuthDossierDossierIdDocumentDocumentIdRouteRoute =
  AuthDossierDossierIdDocumentDocumentIdRouteImport.update({
    id: "/$documentId",
    path: "/$documentId",
    getParentRoute: () => AuthDossierDossierIdDocumentRouteLazyRoute,
  } as any).lazy(() =>
    import(
      "./routes/_auth/dossier/$dossierId/document/$documentId/route.lazy"
    ).then((d) => d.Route),
  );

const AuthDossierDossierIdDocumentDocumentIdIndexRoute =
  AuthDossierDossierIdDocumentDocumentIdIndexImport.update({
    id: "/",
    path: "/",
    getParentRoute: () => AuthDossierDossierIdDocumentDocumentIdRouteRoute,
  } as any);

const AuthDossierDossierIdDocumentDocumentIdEditRoute =
  AuthDossierDossierIdDocumentDocumentIdEditImport.update({
    id: "/edit",
    path: "/edit",
    getParentRoute: () => AuthDossierDossierIdDocumentDocumentIdRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_auth": {
      id: "/_auth";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AuthRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/login": {
      id: "/login";
      path: "/login";
      fullPath: "/login";
      preLoaderRoute: typeof LoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_auth/dossier": {
      id: "/_auth/dossier";
      path: "/dossier";
      fullPath: "/dossier";
      preLoaderRoute: typeof AuthDossierRouteImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/_auth/dashboard": {
      id: "/_auth/dashboard";
      path: "/dashboard";
      fullPath: "/dashboard";
      preLoaderRoute: typeof AuthDashboardImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/_auth/settings": {
      id: "/_auth/settings";
      path: "/settings";
      fullPath: "/settings";
      preLoaderRoute: typeof AuthSettingsRouteLazyImport;
      parentRoute: typeof AuthRouteImport;
    };
    "/login/": {
      id: "/login/";
      path: "/";
      fullPath: "/login/";
      preLoaderRoute: typeof LoginIndexImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId": {
      id: "/_auth/dossier/$dossierId";
      path: "/$dossierId";
      fullPath: "/dossier/$dossierId";
      preLoaderRoute: typeof AuthDossierDossierIdRouteImport;
      parentRoute: typeof AuthDossierRouteImport;
    };
    "/_auth/dossier/": {
      id: "/_auth/dossier/";
      path: "/";
      fullPath: "/dossier/";
      preLoaderRoute: typeof AuthDossierIndexImport;
      parentRoute: typeof AuthDossierRouteImport;
    };
    "/login/resetPassword/": {
      id: "/login/resetPassword/";
      path: "/resetPassword";
      fullPath: "/login/resetPassword";
      preLoaderRoute: typeof LoginResetPasswordIndexImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId/edit": {
      id: "/_auth/dossier/$dossierId/edit";
      path: "/edit";
      fullPath: "/dossier/$dossierId/edit";
      preLoaderRoute: typeof AuthDossierDossierIdEditImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/logs": {
      id: "/_auth/dossier/$dossierId/logs";
      path: "/logs";
      fullPath: "/dossier/$dossierId/logs";
      preLoaderRoute: typeof AuthDossierDossierIdLogsImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/storage": {
      id: "/_auth/dossier/$dossierId/storage";
      path: "/storage";
      fullPath: "/dossier/$dossierId/storage";
      preLoaderRoute: typeof AuthDossierDossierIdStorageImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/workflows": {
      id: "/_auth/dossier/$dossierId/workflows";
      path: "/workflows";
      fullPath: "/dossier/$dossierId/workflows";
      preLoaderRoute: typeof AuthDossierDossierIdWorkflowsImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/document": {
      id: "/_auth/dossier/$dossierId/document";
      path: "/document";
      fullPath: "/dossier/$dossierId/document";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentRouteLazyImport;
      parentRoute: typeof AuthDossierDossierIdRouteImport;
    };
    "/_auth/dossier/$dossierId/document/$documentId": {
      id: "/_auth/dossier/$dossierId/document/$documentId";
      path: "/$documentId";
      fullPath: "/dossier/$dossierId/document/$documentId";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentDocumentIdRouteImport;
      parentRoute: typeof AuthDossierDossierIdDocumentRouteLazyImport;
    };
    "/login/resetPassword/confirm/$uid/$token": {
      id: "/login/resetPassword/confirm/$uid/$token";
      path: "/resetPassword/confirm/$uid/$token";
      fullPath: "/login/resetPassword/confirm/$uid/$token";
      preLoaderRoute: typeof LoginResetPasswordConfirmUidTokenImport;
      parentRoute: typeof LoginRouteImport;
    };
    "/_auth/dossier/$dossierId/document/": {
      id: "/_auth/dossier/$dossierId/document/";
      path: "/";
      fullPath: "/dossier/$dossierId/document/";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentIndexImport;
      parentRoute: typeof AuthDossierDossierIdDocumentRouteLazyImport;
    };
    "/_auth/dossier/$dossierId/document/$documentId/edit": {
      id: "/_auth/dossier/$dossierId/document/$documentId/edit";
      path: "/edit";
      fullPath: "/dossier/$dossierId/document/$documentId/edit";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentDocumentIdEditImport;
      parentRoute: typeof AuthDossierDossierIdDocumentDocumentIdRouteImport;
    };
    "/_auth/dossier/$dossierId/document/$documentId/": {
      id: "/_auth/dossier/$dossierId/document/$documentId/";
      path: "/";
      fullPath: "/dossier/$dossierId/document/$documentId/";
      preLoaderRoute: typeof AuthDossierDossierIdDocumentDocumentIdIndexImport;
      parentRoute: typeof AuthDossierDossierIdDocumentDocumentIdRouteImport;
    };
  }
}

// Create and export the route tree

interface AuthDossierDossierIdDocumentDocumentIdRouteRouteChildren {
  AuthDossierDossierIdDocumentDocumentIdEditRoute: typeof AuthDossierDossierIdDocumentDocumentIdEditRoute;
  AuthDossierDossierIdDocumentDocumentIdIndexRoute: typeof AuthDossierDossierIdDocumentDocumentIdIndexRoute;
}

const AuthDossierDossierIdDocumentDocumentIdRouteRouteChildren: AuthDossierDossierIdDocumentDocumentIdRouteRouteChildren =
  {
    AuthDossierDossierIdDocumentDocumentIdEditRoute:
      AuthDossierDossierIdDocumentDocumentIdEditRoute,
    AuthDossierDossierIdDocumentDocumentIdIndexRoute:
      AuthDossierDossierIdDocumentDocumentIdIndexRoute,
  };

const AuthDossierDossierIdDocumentDocumentIdRouteRouteWithChildren =
  AuthDossierDossierIdDocumentDocumentIdRouteRoute._addFileChildren(
    AuthDossierDossierIdDocumentDocumentIdRouteRouteChildren,
  );

interface AuthDossierDossierIdDocumentRouteLazyRouteChildren {
  AuthDossierDossierIdDocumentDocumentIdRouteRoute: typeof AuthDossierDossierIdDocumentDocumentIdRouteRouteWithChildren;
  AuthDossierDossierIdDocumentIndexRoute: typeof AuthDossierDossierIdDocumentIndexRoute;
}

const AuthDossierDossierIdDocumentRouteLazyRouteChildren: AuthDossierDossierIdDocumentRouteLazyRouteChildren =
  {
    AuthDossierDossierIdDocumentDocumentIdRouteRoute:
      AuthDossierDossierIdDocumentDocumentIdRouteRouteWithChildren,
    AuthDossierDossierIdDocumentIndexRoute:
      AuthDossierDossierIdDocumentIndexRoute,
  };

const AuthDossierDossierIdDocumentRouteLazyRouteWithChildren =
  AuthDossierDossierIdDocumentRouteLazyRoute._addFileChildren(
    AuthDossierDossierIdDocumentRouteLazyRouteChildren,
  );

interface AuthDossierDossierIdRouteRouteChildren {
  AuthDossierDossierIdEditRoute: typeof AuthDossierDossierIdEditRoute;
  AuthDossierDossierIdLogsRoute: typeof AuthDossierDossierIdLogsRoute;
  AuthDossierDossierIdStorageRoute: typeof AuthDossierDossierIdStorageRoute;
  AuthDossierDossierIdWorkflowsRoute: typeof AuthDossierDossierIdWorkflowsRoute;
  AuthDossierDossierIdDocumentRouteLazyRoute: typeof AuthDossierDossierIdDocumentRouteLazyRouteWithChildren;
}

const AuthDossierDossierIdRouteRouteChildren: AuthDossierDossierIdRouteRouteChildren =
  {
    AuthDossierDossierIdEditRoute: AuthDossierDossierIdEditRoute,
    AuthDossierDossierIdLogsRoute: AuthDossierDossierIdLogsRoute,
    AuthDossierDossierIdStorageRoute: AuthDossierDossierIdStorageRoute,
    AuthDossierDossierIdWorkflowsRoute: AuthDossierDossierIdWorkflowsRoute,
    AuthDossierDossierIdDocumentRouteLazyRoute:
      AuthDossierDossierIdDocumentRouteLazyRouteWithChildren,
  };

const AuthDossierDossierIdRouteRouteWithChildren =
  AuthDossierDossierIdRouteRoute._addFileChildren(
    AuthDossierDossierIdRouteRouteChildren,
  );

interface AuthDossierRouteRouteChildren {
  AuthDossierDossierIdRouteRoute: typeof AuthDossierDossierIdRouteRouteWithChildren;
  AuthDossierIndexRoute: typeof AuthDossierIndexRoute;
}

const AuthDossierRouteRouteChildren: AuthDossierRouteRouteChildren = {
  AuthDossierDossierIdRouteRoute: AuthDossierDossierIdRouteRouteWithChildren,
  AuthDossierIndexRoute: AuthDossierIndexRoute,
};

const AuthDossierRouteRouteWithChildren =
  AuthDossierRouteRoute._addFileChildren(AuthDossierRouteRouteChildren);

interface AuthRouteRouteChildren {
  AuthDossierRouteRoute: typeof AuthDossierRouteRouteWithChildren;
  AuthDashboardRoute: typeof AuthDashboardRoute;
  AuthSettingsRouteLazyRoute: typeof AuthSettingsRouteLazyRoute;
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthDossierRouteRoute: AuthDossierRouteRouteWithChildren,
  AuthDashboardRoute: AuthDashboardRoute,
  AuthSettingsRouteLazyRoute: AuthSettingsRouteLazyRoute,
};

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
);

interface LoginRouteRouteChildren {
  LoginIndexRoute: typeof LoginIndexRoute;
  LoginResetPasswordIndexRoute: typeof LoginResetPasswordIndexRoute;
  LoginResetPasswordConfirmUidTokenRoute: typeof LoginResetPasswordConfirmUidTokenRoute;
}

const LoginRouteRouteChildren: LoginRouteRouteChildren = {
  LoginIndexRoute: LoginIndexRoute,
  LoginResetPasswordIndexRoute: LoginResetPasswordIndexRoute,
  LoginResetPasswordConfirmUidTokenRoute:
    LoginResetPasswordConfirmUidTokenRoute,
};

const LoginRouteRouteWithChildren = LoginRouteRoute._addFileChildren(
  LoginRouteRouteChildren,
);

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof AuthRouteRouteWithChildren;
  "/login": typeof LoginRouteRouteWithChildren;
  "/dossier": typeof AuthDossierRouteRouteWithChildren;
  "/dashboard": typeof AuthDashboardRoute;
  "/settings": typeof AuthSettingsRouteLazyRoute;
  "/login/": typeof LoginIndexRoute;
  "/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/dossier/": typeof AuthDossierIndexRoute;
  "/login/resetPassword": typeof LoginResetPasswordIndexRoute;
  "/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/dossier/$dossierId/document": typeof AuthDossierDossierIdDocumentRouteLazyRouteWithChildren;
  "/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdRouteRouteWithChildren;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/dossier/$dossierId/document/": typeof AuthDossierDossierIdDocumentIndexRoute;
  "/dossier/$dossierId/document/$documentId/edit": typeof AuthDossierDossierIdDocumentDocumentIdEditRoute;
  "/dossier/$dossierId/document/$documentId/": typeof AuthDossierDossierIdDocumentDocumentIdIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof AuthRouteRouteWithChildren;
  "/dashboard": typeof AuthDashboardRoute;
  "/settings": typeof AuthSettingsRouteLazyRoute;
  "/login": typeof LoginIndexRoute;
  "/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/dossier": typeof AuthDossierIndexRoute;
  "/login/resetPassword": typeof LoginResetPasswordIndexRoute;
  "/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/dossier/$dossierId/document": typeof AuthDossierDossierIdDocumentIndexRoute;
  "/dossier/$dossierId/document/$documentId/edit": typeof AuthDossierDossierIdDocumentDocumentIdEditRoute;
  "/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_auth": typeof AuthRouteRouteWithChildren;
  "/login": typeof LoginRouteRouteWithChildren;
  "/_auth/dossier": typeof AuthDossierRouteRouteWithChildren;
  "/_auth/dashboard": typeof AuthDashboardRoute;
  "/_auth/settings": typeof AuthSettingsRouteLazyRoute;
  "/login/": typeof LoginIndexRoute;
  "/_auth/dossier/$dossierId": typeof AuthDossierDossierIdRouteRouteWithChildren;
  "/_auth/dossier/": typeof AuthDossierIndexRoute;
  "/login/resetPassword/": typeof LoginResetPasswordIndexRoute;
  "/_auth/dossier/$dossierId/edit": typeof AuthDossierDossierIdEditRoute;
  "/_auth/dossier/$dossierId/logs": typeof AuthDossierDossierIdLogsRoute;
  "/_auth/dossier/$dossierId/storage": typeof AuthDossierDossierIdStorageRoute;
  "/_auth/dossier/$dossierId/workflows": typeof AuthDossierDossierIdWorkflowsRoute;
  "/_auth/dossier/$dossierId/document": typeof AuthDossierDossierIdDocumentRouteLazyRouteWithChildren;
  "/_auth/dossier/$dossierId/document/$documentId": typeof AuthDossierDossierIdDocumentDocumentIdRouteRouteWithChildren;
  "/login/resetPassword/confirm/$uid/$token": typeof LoginResetPasswordConfirmUidTokenRoute;
  "/_auth/dossier/$dossierId/document/": typeof AuthDossierDossierIdDocumentIndexRoute;
  "/_auth/dossier/$dossierId/document/$documentId/edit": typeof AuthDossierDossierIdDocumentDocumentIdEditRoute;
  "/_auth/dossier/$dossierId/document/$documentId/": typeof AuthDossierDossierIdDocumentDocumentIdIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/login"
    | "/dossier"
    | "/dashboard"
    | "/settings"
    | "/login/"
    | "/dossier/$dossierId"
    | "/dossier/"
    | "/login/resetPassword"
    | "/dossier/$dossierId/edit"
    | "/dossier/$dossierId/logs"
    | "/dossier/$dossierId/storage"
    | "/dossier/$dossierId/workflows"
    | "/dossier/$dossierId/document"
    | "/dossier/$dossierId/document/$documentId"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/dossier/$dossierId/document/"
    | "/dossier/$dossierId/document/$documentId/edit"
    | "/dossier/$dossierId/document/$documentId/";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/dashboard"
    | "/settings"
    | "/login"
    | "/dossier/$dossierId"
    | "/dossier"
    | "/login/resetPassword"
    | "/dossier/$dossierId/edit"
    | "/dossier/$dossierId/logs"
    | "/dossier/$dossierId/storage"
    | "/dossier/$dossierId/workflows"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/dossier/$dossierId/document"
    | "/dossier/$dossierId/document/$documentId/edit"
    | "/dossier/$dossierId/document/$documentId";
  id:
    | "__root__"
    | "/"
    | "/_auth"
    | "/login"
    | "/_auth/dossier"
    | "/_auth/dashboard"
    | "/_auth/settings"
    | "/login/"
    | "/_auth/dossier/$dossierId"
    | "/_auth/dossier/"
    | "/login/resetPassword/"
    | "/_auth/dossier/$dossierId/edit"
    | "/_auth/dossier/$dossierId/logs"
    | "/_auth/dossier/$dossierId/storage"
    | "/_auth/dossier/$dossierId/workflows"
    | "/_auth/dossier/$dossierId/document"
    | "/_auth/dossier/$dossierId/document/$documentId"
    | "/login/resetPassword/confirm/$uid/$token"
    | "/_auth/dossier/$dossierId/document/"
    | "/_auth/dossier/$dossierId/document/$documentId/edit"
    | "/_auth/dossier/$dossierId/document/$documentId/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AuthRouteRoute: typeof AuthRouteRouteWithChildren;
  LoginRouteRoute: typeof LoginRouteRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRouteRoute: AuthRouteRouteWithChildren,
  LoginRouteRoute: LoginRouteRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/login"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/dossier",
        "/_auth/dashboard",
        "/_auth/settings"
      ]
    },
    "/login": {
      "filePath": "login/route.tsx",
      "children": [
        "/login/",
        "/login/resetPassword/",
        "/login/resetPassword/confirm/$uid/$token"
      ]
    },
    "/_auth/dossier": {
      "filePath": "_auth/dossier/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/dossier/$dossierId",
        "/_auth/dossier/"
      ]
    },
    "/_auth/dashboard": {
      "filePath": "_auth/dashboard.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings": {
      "filePath": "_auth/settings/route.lazy.tsx",
      "parent": "/_auth"
    },
    "/login/": {
      "filePath": "login/index.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId": {
      "filePath": "_auth/dossier/$dossierId/route.tsx",
      "parent": "/_auth/dossier",
      "children": [
        "/_auth/dossier/$dossierId/edit",
        "/_auth/dossier/$dossierId/logs",
        "/_auth/dossier/$dossierId/storage",
        "/_auth/dossier/$dossierId/workflows",
        "/_auth/dossier/$dossierId/document"
      ]
    },
    "/_auth/dossier/": {
      "filePath": "_auth/dossier/index.tsx",
      "parent": "/_auth/dossier"
    },
    "/login/resetPassword/": {
      "filePath": "login/resetPassword.index.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId/edit": {
      "filePath": "_auth/dossier/$dossierId/edit.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/logs": {
      "filePath": "_auth/dossier/$dossierId/logs.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/storage": {
      "filePath": "_auth/dossier/$dossierId/storage.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/workflows": {
      "filePath": "_auth/dossier/$dossierId/workflows.tsx",
      "parent": "/_auth/dossier/$dossierId"
    },
    "/_auth/dossier/$dossierId/document": {
      "filePath": "_auth/dossier/$dossierId/document/route.lazy.tsx",
      "parent": "/_auth/dossier/$dossierId",
      "children": [
        "/_auth/dossier/$dossierId/document/$documentId",
        "/_auth/dossier/$dossierId/document/"
      ]
    },
    "/_auth/dossier/$dossierId/document/$documentId": {
      "filePath": "_auth/dossier/$dossierId/document/$documentId/route.tsx",
      "parent": "/_auth/dossier/$dossierId/document",
      "children": [
        "/_auth/dossier/$dossierId/document/$documentId/edit",
        "/_auth/dossier/$dossierId/document/$documentId/"
      ]
    },
    "/login/resetPassword/confirm/$uid/$token": {
      "filePath": "login/resetPassword.confirm.$uid.$token.tsx",
      "parent": "/login"
    },
    "/_auth/dossier/$dossierId/document/": {
      "filePath": "_auth/dossier/$dossierId/document/index.tsx",
      "parent": "/_auth/dossier/$dossierId/document"
    },
    "/_auth/dossier/$dossierId/document/$documentId/edit": {
      "filePath": "_auth/dossier/$dossierId/document/$documentId/edit.tsx",
      "parent": "/_auth/dossier/$dossierId/document/$documentId"
    },
    "/_auth/dossier/$dossierId/document/$documentId/": {
      "filePath": "_auth/dossier/$dossierId/document/$documentId/index.tsx",
      "parent": "/_auth/dossier/$dossierId/document/$documentId"
    }
  }
}
ROUTE_MANIFEST_END */
