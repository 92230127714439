import { forwardRef, useMemo } from "react";

import { createLink, LinkComponent } from "@tanstack/react-router";
import {
  mergeProps,
  useFocusRing,
  useHover,
  useLink,
  useObjectRef,
  type AriaLinkOptions,
} from "react-aria";

import { styledToggleButton, type StyledToggleButtonProps } from "@dokworks/ui";

interface RACLinkProps
  extends Omit<AriaLinkOptions, "href" | "isDisabled">,
    Omit<StyledToggleButtonProps, "isDisabled"> {
  className?: string;
  children?: React.ReactNode;
}

const RACLinkButtonComponent = forwardRef<HTMLAnchorElement, RACLinkProps>(
  ({ isSelected, ...rest }: RACLinkProps, forwardedRef) => {
    const ref = useObjectRef(forwardedRef);

    const { onClick, ...props } = rest as RACLinkProps & {
      onClick?: () => void;
    };

    const { linkProps, isPressed } = useLink(
      { ...props, onPress: onClick },
      ref,
    );
    const isDisabled = useMemo(
      () => !("href" in linkProps) || !linkProps.href,
      [linkProps],
    );

    const { hoverProps, isHovered } = useHover({ ...props, isDisabled });
    const { focusProps, isFocusVisible, isFocused } = useFocusRing(props);

    return (
      <a
        {...mergeProps(linkProps, hoverProps, focusProps, props)}
        ref={ref}
        data-hovered={isHovered || undefined}
        data-pressed={isPressed || undefined}
        data-selected={isSelected}
        data-focus-visible={isFocusVisible || undefined}
        data-focused={isFocused || undefined}
        data-disabled={isDisabled || undefined}
        className={styledToggleButton({
          size: props.size,
          variant: props.variant,
          isPressed,
          isSelected,
          isFocused,
          isFocusVisible,
          isDisabled,
          className: props.className,
        })}
      />
    );
  },
);

RACLinkButtonComponent.displayName = "RACLinkButtonComponent";

const CreatedLinkButtonComponent = createLink(RACLinkButtonComponent);

export const TSRACLinkButton: LinkComponent<typeof RACLinkButtonComponent> = ({
  // eslint-disable-next-line react/prop-types
  activeProps,
  // eslint-disable-next-line react/prop-types
  inactiveProps,
  ...props
}) => (
  <CreatedLinkButtonComponent
    preload={"intent"}
    activeProps={mergeProps({ isSelected: true }, activeProps)}
    inactiveProps={mergeProps({ isSelected: false }, inactiveProps)}
    {...props}
  />
);
